export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/warehouse-list/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/warehouse/List.vue'),
        name: 'warehouse-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/warehouse-add/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/warehouse/Add.vue'),
        name: 'warehouse-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/grn-list/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/grn/List.vue'),
        name: 'grn-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/grn-add/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/grn/Add.vue'),
        name: 'grn-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/grn-list-pending/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/grn/pending/List.vue'),
        name: 'grn-list-pending',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/grn-po-add/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/grn/po/Add.vue'),
        name: 'grn-po-add',
        meta: {
            requiresAuth: true
        }
    },
    // STOCK ROUTES
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-transfer-list/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/stock/transfer/List.vue'),
        name: 'stock-transfer-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-transfer-add/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/stock/transfer/Add.vue'),
        name: 'stock-transfer-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-transfer/:pageId/edit/:transferId',
        component: () => import('@/views/page/company/procurement/inventory/stock/transfer/Edit.vue'),
        name: 'stock-transfer-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-consumption-list/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/stock/consumption/List.vue'),
        name: 'stock-consumption-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-consumption-add/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/stock/consumption/Add.vue'),
        name: 'stock-consumption-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-adjustment-list/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/stock/adjustment/List.vue'),
        name: 'stock-adjustment-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-adjustment-add/:pageId',
        component: () => import('@/views/page/company/procurement/inventory/stock/adjustment/Add'),
        name: 'stock-adjustment-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-adjustment/:pageId/edit/:adjustmentId',
        component: () => import('@/views/page/company/procurement/inventory/stock/adjustment/Edit'),
        name: 'stock-adjustment-edit',
        meta: {
            requiresAuth: true
        }
    },
    // Reports
    // {
    //     path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-register/:pageId',
    //     component: () => import('@/views/page/company/procurement/inventory/reports/stock-register/StockRegisterReport.vue'),
    //     name: 'stock-register',
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    // {
    //     path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-ledger/:pageId',
    //     component: () => import('@/views/page/company/procurement/inventory/reports/stock-ledger/StockLedgerReport.vue'),
    //     name: 'stock-ledger',
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
]
