export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/budget-list/:pageId',
        component: () => import('@/views/page/company/procurement/budget/List.vue'),
        name: 'budget-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/budget-add/:pageId',
        component: () => import('@/views/page/company/procurement/budget/Add.vue'),
        name: 'budget-add',
        meta: {
            requiresAuth: true
        }
    },
    // REPORT
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/budget-overview/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/budget-overview/List.vue'),
        name: 'budget-overview',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/budget-actual/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/budget-actual/List.vue'),
        name: 'budget-actual',
        meta: {
            requiresAuth: true
        }
    },
]