export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-service/:pageId/:accountType',
        component: () => import('@/views/page/company/core-accounting/inventory/product-service/List.vue'),
        name: 'inventory-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/product-specification/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/product-service/SpecificationList.vue'),
        name: 'specification-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase/List.vue'),
        name: 'purchase-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase/Add.vue'),
        name: 'purchase-bill-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill/:pageId/edit/:purchaseId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase/Edit.vue'),
        name: 'purchase-bill-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-bill-pay/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase/BillPay.vue'),
        name: 'purchase-bill-pay',
        meta: {
            requiresAuth: true
        }
    },    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-return-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-return/List.vue'),
        name: 'purchase-return-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-return-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-return/Add.vue'),
        name: 'purchase-return-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-return/:pageId/edit/:purchaseReturnId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-return/Edit.vue'),
        name: 'purchase-return-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-return/:pageId/show/:purchaseReturnId',
        component: () => import('@/views/page/company/core-accounting/inventory/purchase-return/Print.vue'),
        name: 'purchase-return-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/List.vue'),
        name: 'sales-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-invoice-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/AddBill.vue'),
        name: 'sales-invoice-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-invoice/:pageId/edit/:salesId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/EditBill.vue'),
        name: 'sales-invoice-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-invoice/:pageId/show/:salesId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/Print.vue'),
        name: 'sales-invoice-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-invoice-pay/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales/MoneyReceipt.vue'),
        name: 'sales-invoice-receipt',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-return-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-return/List.vue'),
        name: 'sales-return-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sales-return-add/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/sales-return/Add.vue'),
        name: 'sales-return-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pay-bill/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/pay-bill/List.vue'),
        name: 'pay-bill-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pay-bill/:pageId/edit/:paymentId',
        component: () => import('@/views/page/company/core-accounting/inventory/pay-bill/Edit.vue'),
        name: 'pay-bill-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/List.vue'),
        name: 'money-receipt-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt/:pageId/edit/:receiptId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/Edit.vue'),
        name: 'money-receipt-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/money-receipt/:pageId/print/:receiptId',
        component: () => import('@/views/page/company/core-accounting/inventory/money-receipt/Print.vue'),
        name: 'money-receipt-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-register/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/inventory-reports/StockRegister.vue'),
        name: 'stock-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/stock-ledger/:pageId',
        component: () => import('@/views/page/company/core-accounting/inventory/inventory-reports/StockLedger.vue'),
        name: 'stock-ledger',
        meta: {
            requiresAuth: true
        }
    },
]
