const state = () => ({
	sidebar:[],
    company: {},
	modules: []
})

const getters = {
	getSidebar: state => state.sidebar,
	getCompany: state => state.company,
	getModules: state => state.modules,
	getCompanyLogo: state => state.company.logo,
}

const mutations = {
	setSidebar: (state, payload) => state.sidebar = payload,
	setCompany: (state, payload) => state.company = payload,
	setModules: (state, payload) => state.modules = payload,
	updateCompanyInfo: (state,data) => {
		state.company.address	= data.address
		state.company.email		= data.email
		state.company.id		= data.id
		state.company.name		= data.name
		state.company.phone		= data.phone
	} 
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}