import Network from '@/services/network'
import {useStatistics} from '@/services/modules/dashboard/statistics.js'
import figureFormatter from '@/services/utils/figureFormatter.js'
import { ref } from 'vue'

export default function handleCompany() {
    const network = new Network;
    const loading = ref(false)
    const {makeStatistics} = useStatistics()
    const {commaFormat} = figureFormatter()

    const fetchSidebar = (query) => {
      return network.api('get', '/get/sidebar'+query);
    }

    const fetchCompanyInfo = (id) => {
      return network.api('get', '/company/'+id);
    }

    const fetchCompanyModules = (id) => {
      return network.api('get', '/get/modules/'+id);
    }

    const createCompany = (data) => {
      return network.api('post', '/company', data);
    }
     // fetchUpdateCompany
    const fetchUpdateCompany = (id,data) => {
      return network.api('post', '/company/'+id,data);
    }

    const fetchCompanyList = () => {
      return network.api('get', '/company');
    }

    const fetchMenu = (query) => {
      return network.api('get', '/menu/comparing'+query);
    }

    const updateMenu = (data) => {
      return network.api('post', '/menu/update', data);
    }
    const compareWithUpdateMenu = (query) => {
        return network.api('get', '/menu/compare-with-update'+query);
    }
    const getDashboard = (companyId) => {
      return network.api('get', '/get/dashboard?module_id=1&company_id='+companyId);
    }

    return {
        fetchSidebar,
        fetchCompanyInfo,
        fetchCompanyModules,
        createCompany,
        fetchUpdateCompany,
        fetchCompanyList,
        fetchMenu,
        updateMenu,
        compareWithUpdateMenu,
        getDashboard,
        makeStatistics,
        commaFormat,
        loading
    }
}
