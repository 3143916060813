const axios = require('axios');
import store from '@/store';

const config = {
	onUploadProgress: function(progressEvent) {
	  var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
	  store.commit('updateProgress', percentCompleted);
	},
	onDownloadProgress: function (progressEvent) {
	},
}

/* =========interceptor code for loader=========== */
let numberOfAjaxCAllPending = 0;

// request interceptor
axios.interceptors.request.use(function (config) {
    numberOfAjaxCAllPending++;
    store.commit('setLoading', true)
    return config;
}, function (error) {
	store.commit('setLoading', false)
    return Promise.reject(error);
});

// response interceptor
axios.interceptors.response.use(function (response) {
    numberOfAjaxCAllPending--;

    if (numberOfAjaxCAllPending == 0) {
		store.commit('setLoading', false)
    }
    return response;
}, function (error) {
    numberOfAjaxCAllPending--;
    if (numberOfAjaxCAllPending == 0) {
        store.commit('setLoading', false)
    }
    return Promise.reject(error);
});
/* =========interceptor code for loader=========== */

export default class Network{
	constructor() {
        this.baseURL = process.env.VUE_APP_BASE_URL;
		axios.defaults.baseURL = `${this.baseURL}/api`;
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        //axios.defaults.withCredentials = true;
	}


	async api(method, url, data = null) {
		return new Promise((resolve, reject) => {
			axios[method.toLowerCase()](url, data, config)
				.then(res => {
					resolve(res.data);
				})
				.catch(err => {
					if(err.response?.status == 401) {
						this.unauthenticated()
					}
					reject(err);
				})
		});
	}

	unauthenticated() {
		window.location.href = '/login?message="Your session has expired"'
	}

}