/*
    "start": "2022-01-01",
    "end": "2022-02-28"
*/

import endOfYear from 'date-fns/endOfYear'
import startOfYear from 'date-fns/startOfYear'
import format from 'date-fns/format'
import subYears from 'date-fns/subYears'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import subMonths from 'date-fns/subMonths'
import startOfQuarter from 'date-fns/startOfQuarter'
import lastDayOfQuarter from 'date-fns/lastDayOfQuarter'

const today = new Date()
const dateFormat = 'yyyy-MM-dd'

export default function useDate() {
    const getQuarter = () => {

    }
    const getFirstDateOfMonth = () => {
        return startOfMonth(today)
    }
    const getLastDateOfMonth = () => {
        return endOfMonth(today)
    }
    const getFirstDateOfLastMonth = () => {
        return subMonths(getFirstDateOfMonth(),1)
    }
    const getLastDateOfLastMonth = () => {
        return subMonths(getLastDateOfMonth(),1)
    }
    const getFirstDateOfQuarter = () => {
        return startOfQuarter(today)
    }
    const getLastDateOfQuarter = () => {
        return lastDayOfQuarter(today)
    }
    const getFirstDateOfYear = () => {
        return startOfYear(today)
    }
    const getLastDateOfYear = () => {
        return endOfYear(today)
    }
    const getFirstDateOfLastYear = () => {
        return subYears(getFirstDateOfYear(),1)
    }
    const getLastDateOfLastYear = () => {
        return subYears(getLastDateOfYear(),1)
    }

    return {
        firstDateOfQuarter: format(getFirstDateOfQuarter(), dateFormat),
        lastDateOfQuarter: format(getLastDateOfQuarter(), dateFormat),
        lastDateOfYear: format(getLastDateOfYear(), dateFormat),
        firstDateOfYear: format(getFirstDateOfYear(), dateFormat),
        firstDateOfLastYear: format(getFirstDateOfLastYear(), dateFormat),
        lastDateOfLastYear: format(getLastDateOfLastYear(), dateFormat),
        firstDateOfMonth: format(getFirstDateOfMonth(), dateFormat),
        lastDateOfMonth: format(getLastDateOfMonth(), dateFormat),
        firstDateOfLastMonth: format(getFirstDateOfLastMonth(), dateFormat),
        lastDateOfLastMonth: format(getLastDateOfLastMonth(), dateFormat),
    }
}