export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-quotation-approved-list/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/ApprovedCSQuotationList.vue'),
        name: 'cs-quotation-approved-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-add/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/AddNewPO.vue'),
        name: 'po-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-list-pending/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/PendingPOList.vue'),
        name: 'po-list-pending',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-proceed/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/ProceedPO.vue'),
        name: 'po-proceed',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-list/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/POList.vue'),
        name: 'po-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-register/:pageId',
        component: () => import('@/views/error/UnderConstruction.vue'),
        name: 'po-register',
        meta: {
            requiresAuth: true
        }
    },
]
