export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-list/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/List.vue'),
        name: 'requisition-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-add/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/Add.vue'),
        name: 'requisition-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-pending/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/pending/List.vue'),
        name: 'requisition-pending',
        meta: {
            requiresAuth: true
        }
    },
    // PR
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pr-allocation-list/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/pr-allocation/List.vue'),
        name: 'pr-allocation-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pr-allocation-add/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/pr-allocation/Add'),
        name: 'pr-allocation-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-register/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/register/List'),
        name: 'requisition-register',
        meta: {
            requiresAuth: true
        }
    },
]