export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/chart-of-accounts/:pageId/:accountType',
        component: () => import('@/views/page/company/core-accounting/account-setting/chart-of-account/List.vue'),
        name: 'accounts-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/business-location/:pageId/:accountType',
        component: () => import('@/views/page/company/core-accounting/account-setting/business-location/List.vue'),
        name: 'business-location',
        meta: {
            requiresAuth: true
        }
    },
]
