export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-list/:pageId',
        component: () => import('@/views/page/company/procurement/project/List.vue'),
        name: 'project-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-add/:pageId',
        component: () => import('@/views/page/company/procurement/project/Add.vue'),
        name: 'project-add',
        meta: {
            requiresAuth: true
        }
    },
]